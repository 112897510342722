.totalizer {
  position: relative;
  background: var(--color-white);
  border-top: none;
  height: auto;
  padding: 20px 0;
  display: block;

  &.active {
    display: none;
  }

  @media (min-width: 768px) {
    height: 120px;
    border-top: 1px solid var(--color-gray);
    padding: 10px 0;

    &.active {
      display: block;
    }
  }
}

.container {
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
  transition: all ease-in-out 0.3s;

  &.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

.number {
  color: var(--color-white);
  font-weight: 700;
  font-size: 20px;
  padding: 5px 25px;
  background: var(--color-primary);
  border-radius: 50px;
  margin-bottom: 5px;
  letter-spacing: 1.5px;

  @media (min-width: 768px) {
    font-size: 22px;
  }
}

.label {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}
