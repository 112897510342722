.heading {
  padding: 20px 20px 30px;
  position: relative;
  top: -1px;
  background: var(--color-light-gray);
  z-index: 10;

  @media (min-width: 768px) {
    position: sticky;
  }
}

.number {
  color: var(--color-primary);
  text-transform: uppercase;
  letter-spacing: 1;
  font-weight: 700;
  margin-bottom: 0;
}
