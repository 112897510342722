.root {
  padding: 2px;
}

.step {
  border-radius: 50%;
  padding: 5px;
  border: 1px solid transparent;
  transition: all ease 0.3s;
}

.inner {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: var(--color-gray);

  @media (min-width: 768px) {
    width: 22px;
    height: 22px;
  }
}

.active {
  border-color: var(--color-primary);
  background: var(--color-white);

  > div {
    background: var(--color-primary);
  }
}

.complete > div {
  background: var(--color-primary);
}

.line {
  height: 1px;
  width: 20px;
  margin: 0 5px;
  background: var(--color-gray);
  transition: all ease 0.3s;

  &.active {
    background: var(--color-primary);
  }

  @media (min-width: 768px) {
    width: 50px;
  }
}
