.root {
  text-align: center;

  img {
    height: 120px;
    margin-bottom: 30px;
  }

  p {
    margin: 10px 0;
  }

  @media (min-width: 768px) {
    img {
      height: 200px;
    }
  }
}
