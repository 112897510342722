.root {
  padding: 45px 40px 20px;
  transition: all ease 0.3s 0.4s;

  h3 {
    color: #878787;
    font-weight: 400;
    font-size: 19px;
  }
}

.title {
  font-size: 40px;
  color: var(--color-primary);
  font-weight: 600;
  margin-bottom: 0px;
}
