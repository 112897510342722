.root {
  padding: 30px 0 100px;
  color: var(--color-text);

  h3,
  h2 {
    color: #333;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-gray);
  }

  h2 {
    font-size: 26px;
  }
}

.item {
  margin-bottom: 20px;
}

.total {
  display: flex;
  justify-content: space-between;
  font-weight: 700;

  > div {
    text-transform: uppercase;
  }

  &.shipping {
    color: var(--color-primary);
    border-top: 1px solid var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
  }
}

.line {
  margin-bottom: 20px;
}

.summary {
  background: var(--color-white);
  border: 1px solid var(--color-gray);
  border-radius: 20px;
  margin-top: 30px;

  h3 {
    text-align: center;
    padding-bottom: 15px;
  }
}

.tax_credit {
  border-top: 1px solid var(--color-gray);
  border-bottom: 1px solid var(--color-gray);
  margin-top: 30px;
  padding: 20px 0;
}

.discount {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
