.banner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding: 0.5rem 1rem 1rem;
  background-color: var(--color-pink);
  color: #fff;

  @media (min-width: 768px) {
    justify-content: space-between;
    gap: 1rem;
    padding: 0.5rem 1rem;
  }
}

.countdown {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (min-width: 768px) {
    margin-top: -5px;
  }
}

.timeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeValue {
  font-size: 1.4rem;

  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
}

.timeLabel {
  font-size: 0.7rem;
}

.rightText {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}
