.slide {
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  height: 100%;
  width: 100%;
}

.slide_header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  background: #fff;
  color: #707070;
  padding: 15px 30px;

  h4 {
    margin: 0 0 5px;
    font-size: 22px;
    color: var(--color-primary);

    @media (min-width: 768px) {
      font-size: 25px;
    }
  }
}

.image_container {
  position: relative;
  width: 100%;
  height: 320px;

  @media (min-width: 768px) {
    height: 400px;
  }

  @media (min-width: 960px) {
    height: 500px;
  }
}

.strikethrough {
  color: var(--color-pink);
  text-decoration: line-through;
}

.price {
  color: #707070;
}

.sale_price {
  color: var(--color-pink);
  margin-left: 8px;
}

.sale_label {
  margin-top: 5px;
  font-style: italic;
  color: var(--color-pink);
  font-weight: normal;
}
