.banner {
  background: var(--color-secondary);
  padding: 10px;
  color: var(--color-white);
  margin-top: -2px;
}

.partner {
  background: var(--color-white);
  border-radius: 20px;
  border: 1px solid var(--color-gray);
  max-width: 560px;
  margin: 40px auto;
  padding: 30px;

  p {
    line-height: 1.5;
  }

  h2 {
    margin: 0;
    text-align: center;
  }
}

.table {
  width: 100%;

  td {
    padding: 10px;
  }

  td:first-of-type {
    font-weight: 700;
  }
}

.line td {
  border-top: 1px solid var(--color-gray);
}

.error td {
  color: var(--color-red);
}

.max_error {
  color: var(--color-red);
  font-size: 14px;
  margin-top: 10px;
}

.grid {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.grid_item {
  position: relative;
  background: var(--color-white);
  border-radius: 20px;
  border: 1px solid var(--color-gray);
  min-height: 330px;
  padding: 0 20px;

  @media (min-width: 768px) {
    padding: 0 40px;
  }

  &.active {
    span {
      transform: translateX(0);
    }

    svg {
      opacity: 1;
    }
  }
}

.title {
  width: 100%;
  padding: 20px;
  font-size: 17px;
  border-bottom: 1px solid var(--color-gray);

  @media (min-width: 768px) {
    font-size: 20px;
  }

  svg {
    height: 26px;
    margin-right: 15px;
    fill: var(--color-primary);
    opacity: 0;
    transition: all ease 0.3s;
  }

  span {
    transform: translateX(-15px);
    transition: all ease 0.3s;
  }
}

.pay_wrapper {
  height: 260px;
  padding: 15px 0 30px;
  flex: 1;
  width: 100%;

  > span {
    font-size: 12px;
    color: #8d8d8d;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
  }

  img {
    height: 28px;
    margin: 0 auto 10px;
  }

  form {
    width: 100%;
  }
}

.btn_select {
  border: none;
  outline: none;
  background: rgba(0, 0, 0, 0);
  z-index: 100;
  width: 100%;
  border-radius: 5px;

  &.hidden {
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }
}

.btn_submit {
  width: 100%;
  margin-top: 20px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 18px;
  padding: 15px 30px;
  background: var(--color-primary);
  color: var(--color-white);
  border: none;

  &:disabled {
    pointer-events: none;
    color: var(--color-secondary);
    background: var(--color-light-gray);
  }
}

.pending {
  overflow: hidden;
  max-height: 25px;
}

.stripe_error {
  margin-top: 20px;
  color: #eb1c26;
  font-size: 14px;
}
