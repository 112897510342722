.root {
  input[type='text'],
  input[type='email'],
  input[type='number'] {
    padding: 0 20px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px 0 30px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    background: #fff;
    border: 1px solid var(--color-gray);
    border-radius: 40px;
    transition: all ease 0.3s;
    outline: none;

    svg {
      margin-right: 15px;
      fill: #333;
      transition: all ease 0.3s;
    }

    &:hover,
    &:focus,
    &.active {
      border-color: var(--color-primary);
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);

      svg {
        fill: var(--color-primary);
      }
    }
  }
}

.notice {
  font-size: 14px;
  margin: 10px 10px 20px;
  color: #383838;
}
