.close {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px;
  font-size: 20px;
  background: black;
  border: none;
  color: #fff;

  @media (min-width: 768px) {
    top: 30px;
    right: 30px;
    background: none;
  }
}

.table {
  overflow-x: auto;
  margin: 20px;
  background: #fff;
  width: 100%;
  max-width: 800px;
  height: max-content;
  margin-top: 60px;

  @media (min-width: 768px) {
    margin-top: 100px;
  }

  table {
    width: 100%;
  }

  th {
    background: var(--color-primary);
    color: #fff;
    padding: 5px;
  }

  td {
    padding: 5px;

    &:first-of-type {
      font-weight: 700;
      padding: 5px 10px;
    }

    &:not(:first-of-type) {
      text-align: center;
    }
  }

  tr:nth-of-type(even) td {
    background: #f7f7f7;
  }
}
