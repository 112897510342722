.root {
  background: var(--color-dark);
  overflow-y: scroll;
  padding: 20px;
  gap: 30px;
  align-items: flex-start; // Default alignment
  justify-content: flex-start;

  > div {
    width: 100%;
    text-align: center;
  }

  @media (min-width: 768px) {
    padding: 0;
    align-items: center;
  }

  h1 {
    color: #fff;
    margin: 0; // Default margin
    padding: 10px 0 30px;
    font-size: 24px; // Default font-size
    text-transform: uppercase;
    letter-spacing: 1px;

    // Responsive adjustments
    @media (min-width: 768px) {
      margin: 0 0 20px;
      font-size: 32px;
    }
  }
}

.heading {
  text-align: center;
}

.hempwool {
  background: var(--color-hempwool);
}

.hempcrete {
  background: var(--color-hempcrete);
}

.button {
  position: relative;
  margin: 0 0 20px; // Default margin
  padding: 20px 0 0;
  border: none;
  border-radius: 20px;
  overflow: hidden;
  width: 100%; // Default width
  transition: all ease 0.3s;

  &:hover {
    transform: translateY(-5px);
  }

  // Responsive adjustments
  @media (min-width: 768px) {
    margin: 20px;
    width: auto;
  }
}

.label {
  position: absolute;
  bottom: 40px;
  left: 40px;
  right: 40px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 18px;
  padding: 20px;
  background-color: rgba(33, 32, 32, 0.34);
  border-radius: 12px;
  backdrop-filter: blur(3px);
}

.image_container {
  width: 100%; // Default width
  height: 220px; // Default height
  position: relative;
  background: #fff;

  // Responsive adjustments
  @media (min-width: 768px) {
    width: 360px;
    height: 360px;
  }
}
