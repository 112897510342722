.header {
  height: var(--height-header);
  position: sticky;
  top: -1px;
  z-index: 1000;
  background: #fff;
  padding: 25px 30px;
  border-bottom: 1px solid var(--color-gray);

  svg {
    display: none;
  }

  @media (min-width: 768px) {
    position: relative;
    padding: 25px 60px;

    svg {
      display: flex;
    }
  }
}

.logo {
  height: 55px;
  margin-right: 20px;

  @media (min-width: 768px) {
    height: 70px;
    margin-right: 60px;
  }
}

.toggle {
  font-size: 12px; // Default state
  font-weight: 500;
  padding: 8px 20px 10px;
  background: var(--color-light-gray);
  border-radius: 20px;
  border: none;
  color: var(--color-text);
  margin-right: 10px;

  &.active {
    background: var(--color-primary);
    color: #fff;
  }

  @media (min-width: 768px) {
    font-size: 14px;
  }
}

.back {
  font-size: 15px; // Default state
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: var(--color-secondary);
  opacity: 0;
  visibility: hidden;
  transform: translateX(-10px);
  transition: all ease 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  &:hover {
    color: var(--color-primary);

    svg {
      transform: translateX(-5px);
    }
  }

  svg {
    height: 15px;
    margin-right: 10px;
    fill: var(--color-secondary);
    transition: all ease 0.3s;
  }

  @media (min-width: 768px) {
    font-size: 17px;

    svg {
      height: 16px;
    }
  }
}
