.instructions {
  border-top: 1px solid var(--color-gray);
  padding-top: 20px;
  margin-bottom: 20px;
}

.option {
  margin-bottom: 20px;
  border: 1px solid var(--color-gray);
  background: #fff;
  padding: 10px 20px 10px 10px;
  border-radius: 3px;
  transition: all ease 0.3s;

  input {
    margin-top: 6px;
    margin-right: 0;
  }

  label {
    cursor: pointer;
    width: 100%;
  }

  a {
    width: 120px;
    text-align: right;
    transition: all ease 0.3s;
  }

  &.selected {
    border-color: var(--color-primary);
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
  }
}

.pickup_message {
  padding-top: 20px;
  margin-bottom: 10px;
}
