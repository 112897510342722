.container {
  margin: 30px 0;

  h3 {
    margin: 0 0 10px;
  }

  strong {
    margin-right: 10px;
  }
}

.location {
  width: 100%;
  color: #858585;

  @media (min-width: 768px) {
    width: auto;
  }
}

.load_message {
  font-size: 16px;
  color: #aaa;
  margin-top: 0px;

  @media (min-width: 768px) {
    font-size: 20px;
    margin-top: -50px;
  }
}

// Quote Cards

.quote_group {
  padding-bottom: 50px;
}

.button {
  flex-direction: column; // Default state
  width: 100%;
  justify-content: center; // Default state
  background: var(--color-gray);
  border: 2px solid transparent;
  border-radius: 20px;
  padding: 15px; // Default state
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  outline: none;
  margin-bottom: 30px;
  transition: all ease 0.3s;

  h4 {
    margin: 0 0 5px;
    text-transform: uppercase;
    color: var(--color-secondary);
    letter-spacing: 1px;
    font-size: 11px;
  }

  @media (min-width: 1275px) {
    flex-direction: row; // Adjusted for larger screens
    justify-content: flex-start; // Adjust if needed
    padding: 10px 20px; // Adjusted padding for larger screens
  }

  &.active,
  &:hover,
  &:focus {
    border-color: var(--color-primary);
    background: var(--color-white);
    box-shadow: 1px 5px 11px 2px rgba(0, 0, 0, 0.09);

    svg {
      fill: var(--color-primary);
    }
  }
}

.carrier_row {
  max-width: 400px;
  height: auto;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    height: 100%;
    margin-bottom: 0;
  }
}

.carrier {
  letter-spacing: 0.5px;
  margin-top: 5px;
  margin-bottom: 20px; // Default state
  font-size: 18px;
  text-align: left;
  transition: all ease 0.3s;

  @media (min-width: 1275px) {
    margin-bottom: 0; // Adjusted for larger screens
  }
}

.transit_time {
  font-size: 17px;
  min-width: 120px;
  color: var(--color-secondary);
  padding: 15px 20px;
}

.row {
  flex-wrap: wrap;

  @media (min-width: 1275px) {
    flex-wrap: initial;
  }
}

.row_inner {
  width: 100%;
  margin-bottom: 5px;
  justify-content: space-between;

  &.center {
    justify-content: center;
  }

  @media (min-width: 1275px) {
    width: auto;
    margin-bottom: 0;
    justify-content: center;
  }
}

.column {
  margin: 0 10px; // Default state
  width: max-content;

  @media (min-width: 1275px) {
    margin: 0 25px; // Adjusted for larger screens
  }
}

.delivery_date {
  display: inline-block;
  text-align: center;
  font-weight: 700;
  padding: 5px 15px;
}

.price {
  margin-top: 15px; // Default state
  margin-left: 0px;
  justify-content: center;
  width: 100%; // Default state
  height: auto; // Default state
  font-size: 21px;
  transition: all ease 0.3s;

  @media (min-width: 1275px) {
    justify-content: flex-end;
    margin-top: 0;
    margin-left: 30px;
    width: auto;
    height: 100%;
  }
}

.day {
  font-size: 18px;
  color: var(--color-secondary);
}

.month {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
