.root {
  min-height: 60vh;
  padding-bottom: 100px;

  select {
    min-width: 200px; // Default state
    margin-right: 10px; // Default state
    font-size: 14px; // Default state
    padding: 0 12px; // Default state

    @media (min-width: 768px) {
      min-width: 220px;
      margin-right: 15px;
      font-size: 15px;
      padding: 0 20px;
    }
  }
}

// Hempwool

.category {
  margin: 0 0 20px;
  border-bottom: 1px solid var(--color-gray);
  padding: 0 20px 10px;

  h5 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }
}

.pallets {
  font-size: 14px;
  margin: 5px 40px 15px;
  color: #909090;
}

.max_error {
  color: var(--color-red);
}

.shipping_error {
  margin-top: 40px;
  border: 1px solid var(--color-red);
  color: var(--color-red);
  padding: 20px;
}

.btn_back {
  border: none;
  background: none;
  font-size: 17px;
  margin-bottom: 10px;
  transition: all ease 0.3s;

  svg {
    height: 16px;
    margin-right: 10px;
    transition: all ease 0.3s;
  }

  &:hover,
  &:focus {
    color: var(--color-primary);

    svg {
      fill: var(--color-primary);
    }
  }
}

.col {
  font-weight: 700;
  font-size: 14px;
  color: #959595;
  margin-bottom: 10px;
}

.col_left {
  padding-left: 20px;
  min-width: 230px;
  flex: 1;
}

.col_right {
  margin-left: 15px;
  width: 125px;
}

.sqft_wrapper {
  max-width: 125px;
  overflow: hidden;
  position: relative;

  &:after {
    content: 'ft²';
    font-size: 14px; // Default font size
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 13px; // Default position
    color: #cccccc;

    @media (min-width: 768px) {
      // Example breakpoint for larger screens
      font-size: 15px; // Adjusted font size for larger screens
      right: 35px; // Adjusted position for larger screens
    }
  }

  &.cubic::after {
    content: 'ft³';
  }
}

.sqft {
  position: relative;
  flex: 1;
  font-size: 15px;
  padding: 0 10px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 0 10px 0 20px;
  }

  &:after {
    content: 'ft²';
    position: absolute;
  }
}

// Hempcrete

.instructions {
  margin: 20px 15px;
  font-size: 13px;
  color: #959595;
}

.hempcrete_types {
  margin: 10px;

  svg {
    display: inline-flex;
    height: 40px;
  }

  button {
    border: 2px solid transparent;
    border-radius: 20px;
    background: var(--color-gray);
    color: var(--color-text);
    padding: 20px 20px 25px;
    width: 100%;
    margin-bottom: 20px;
    transition: all ease 0.3s;

    svg {
      fill: var(--color-primary);
      transition: all ease 0.3s;
    }

    &:hover,
    &:focus {
      // Uncomment the next line if you decide to change the text color on hover and focus
      // color: var(--color-white);
      border-color: var(--color-primary);
      background: var(--color-white);
      transform: translateY(-5px);
    }
  }
}

.title {
  font-weight: 700;
  font-size: 17px;
  margin: 5px 0 5px;
}

.subtitle {
  font-size: 12px;
}

// Buttons

.btn_add {
  color: var(--color-primary);
  background: #fff;
  padding: 0 20px;
  transition: all ease 0.3s;

  &:hover,
  &:focus {
    transform: translateY(-3px);
    box-shadow: 1px 3px 12px -7px rgb(156 205 51);
  }

  svg {
    height: 16px;
    margin-right: 10px;
  }
}

.btn_pricing {
  z-index: 1;
  padding: 0 20px;
  border: none;
  text-align: right;
  background: none;
  color: var(--color-primary);
}

.btn_remove {
  height: 50px;
  border: none;
  background: none;
  outline: none;
  padding-left: 10px; // Default padding-left
  padding-right: 0; // Default padding-right

  @media (min-width: 768px) {
    // Example breakpoint for larger screens
    padding-left: 15px; // Adjusted padding-left for larger screens
    padding-right: 10px; // Adjusted padding-right for larger screens
  }

  svg {
    height: 16px;
    fill: #939393;
    transition: fill ease 0.3s;

    &:hover {
      fill: var(--color-primary);
    }
  }
}
