.button {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
  right: 70px;
  top: 45px;
  border: none;
  background: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-bottom: 20px;
  transition: all ease 0.3s;
  outline: none;

  svg {
    height: 30px;
    transition: all ease 0.3s;

    path {
      stroke: var(--color-primary);
    }
  }

  @media (min-width: 768px) {
    display: flex;
  }
}

.form {
  background: #fff;
  border-top: 5px solid var(--color-primary);
  width: 80%; // Default width
  border-radius: 3px;
  padding: 30px 40px;

  @media (min-width: 768px) {
    width: 400px;
  }

  h2 {
    text-align: center;
  }

  label {
    font-size: 13px;
    letter-spacing: 1px;
    padding: 0 10px;
  }

  input {
    width: 100%;
    height: 50px;
    font-size: 15px;
    border: 1px solid var(--color-gray);
    font-family: var(--font-body);
    border-radius: 40px;
    padding: 0 20px;
    margin: 10px 0 20px;
    outline: none;
  }
}

.submit {
  margin-top: 10px;
  background: var(--color-primary);

  &:disabled {
    background: var(--color-gray);
  }
}

.error {
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  color: var(--color-red);
}

.confirm {
  padding-top: 30px;
}

.success {
  font-size: 20px;
  font-weight: 700;
  color: var(--color-primary);
}

.notice {
  text-align: center;
  margin: 20px 0;
}

.btn_continue {
  background: var(--color-dark);
  margin: 10px 0;
}

.btn_remove {
  color: var(--color-red);
  border: 2px solid var(--color-red);

  &:hover {
    background: var(--color-red);
    color: var(--color-white);
  }
}
