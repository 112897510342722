.root {
  position: relative;

  button svg {
    height: 16px;
    fill: #c2c2c2;
    margin-top: 2px;
    margin-left: 5px;
  }

  &:hover .content {
    opacity: 1;
    visibility: visible;
    transform: translate3d(-50%, 0, 0);
  }
}

.content {
  position: absolute;
  background: #fff;
  color: #222;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.6;
  bottom: 120%;
  left: 50%;
  transform: translate3d(-50%, 5px, 0);
  padding: 15px 20px;
  z-index: 100;
  width: 280px;
  box-shadow: 1px 2px 21px rgba(0, 0, 0, 0.18);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translate3d(-50%, 0, 0);
  }
}
