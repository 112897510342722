.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}

.notice {
  margin-top: 40px;
  border: 1px solid var(--color-gray);
  background: #fff;
  color: #747474;
  padding: 20px;

  h4 {
    margin-bottom: 10px !important;
    text-transform: uppercase;
  }
}

.ship_field {
  padding: 0 20px;
  grid-column: span 2;
}

.ship_label {
  padding-left: 10px !important;
}

.description {
  margin: 10px 0 30px;
  font-size: 14px;
  color: #383838;
}

.refresh {
  margin-top: 20px;
  margin-bottom: 50px;
  background: #3b3b3b;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 20px;
    fill: #fff;
    margin-right: 10px;
  }
}
