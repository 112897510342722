.root {
  height: auto; /* Default height */
  z-index: 1;
  border-top: 1px solid;
  border-color: var(--color-gray);
  padding: 20px;

  ul {
    height: 100%;
    display: block; /* Default display */
    text-align: center;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 12px;
    flex-wrap: wrap;
  }

  li {
    padding: 5px; /* Default padding */
    border-right: none;

    &:last-of-type {
      border: none;
    }
  }

  a {
    transition: all ease 0.3s;
    color: #4f4f4f;

    &:hover {
      color: var(--color-primary);
    }
  }

  @media (min-width: 768px) {
    padding: 0px;
    height: var(--height-footer, 68px); /* Default height */
    ul {
      display: flex;
    }

    li {
      padding: 0 20px;
      border-right: 1px solid var(--color-gray);
    }
  }
}

.support {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--color-gray);
  font-size: 12px;
  padding: 8px 10px;
  margin-bottom: 10px; /* Default margin-bottom */

  a {
    color: var(--color-primary);
    font-weight: 700;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}
