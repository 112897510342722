.switch {
  position: relative;
  width: 75px;

  label {
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: 30px;
    margin: 0;
    min-width: 75px;
    min-height: 40px;
    padding: 1px 5px;
    transition: all ease 0.3s;
    background: #adadad;
    outline: none;
  }

  input {
    display: none;
  }

  &.active {
    span {
      transform: translateX(calc(5px + 100%));
    }

    label {
      background: var(--color-primary);
    }
  }
}

.inner {
  display: block;
  background: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  outline: none;
  transition: transform ease 0.3s;
}
