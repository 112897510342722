.root {
  display: grid;
  gap: 0;
  grid-template-columns: 1fr;
  overflow: hidden;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 550px;
  }
}

.col {
  height: auto;

  @media (min-width: 768px) {
    height: calc(100vh - calc(var(--height-footer) + var(--height-banner)));
  }
}

.col_form {
  background: var(--color-light-gray);
  z-index: 1;
  border-left: 1px solid var(--color-gray);

  @media (min-width: 768px) {
    max-height: 100vh;
    overflow-y: scroll;
  }
}

.content {
  overflow: scroll;
  flex: 1;
  background: linear-gradient(220deg, white -20%, #f0f0f0);
}

.media {
  height: auto;
  background: var(--color-light-gray);

  @media (min-width: 768px) {
    height: calc(
      100vh -
        calc(var(--height-header) + var(--height-footer) + var(--height-banner))
    );
  }
}

.loader {
  margin-top: 30px;
}

.form_wrapper {
  max-width: 100vw;
  padding: 0 15px;

  @media (min-width: 768px) {
    padding: 0 30px;
  }
}
